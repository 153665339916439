import Decimal from 'decimal.js'
import React, { useState } from 'react'

function Extra({ maxCount, extra, orders, setOrders, index, dish, totalPrice, setTotalPrice, handleChangeExtra }) {
    const [count, setCount] = useState(1)
    let copytotalPrice = new Decimal(totalPrice)
    const handleExtraCount = (e, price, extra_id) => {
        let newCounter = new Decimal(e.target.value)
        let formatedPrice = new Decimal(price)
        let copyCount = new Decimal(count)

        copytotalPrice = copytotalPrice.sub(formatedPrice.mul(copyCount)).add(formatedPrice.mul(newCounter))
        setTotalPrice(copytotalPrice)
        setCount(newCounter);
        let index = orders.findIndex((order) => (order.id == dish.id));
        let copyOrder = [...orders]
        if (index != -1) {
            let extraIndex = copyOrder[index].extras.findIndex((extra) => (extra.id == extra_id));
            if (extraIndex != -1) {
                copyOrder[index].extras[extraIndex].quantity = newCounter.toString()
            }
        }
        setOrders(copyOrder)
    }
    return (
        <div className='d-flex align-items-center gap-3 w-100 ps-5 mt-2'>
            <input disabled={orders?.filter((order) => (order.id == dish.id)).length == 0} checked={orders?.filter((order) => (order.id == dish.id &&
                order.extras?.filter((extra) => (extra.id == index)).length > 0)).length > 0} id={`check_${index}_extra_${dish.id}`} type='checkbox' onChange={(e) => handleChangeExtra(e, index, dish.id, extra)} />
            <label for={`check_${index}_extra_${dish.id}`} className='checkbox_label w-100'>
                <span>{extra.extra}</span>
                <span className='price d-flex gap-2 align-items-center'>
                    <div><input id={`input_extra_${index}_${dish.id}`} disabled={orders?.filter((order) => (order.id == dish.id &&
                        order.extras?.filter((extra) => (extra.id == index)).length > 0)).length == 0} type='number' max={maxCount} min={1} defaultValue={1} onChange={(e) => handleExtraCount(e, extra.price, index)} /></div>
                    +{extra.price} €
                </span>
            </label>
        </div>
    )
}

export default Extra