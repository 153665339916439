import Decimal from 'decimal.js'
import React, { useState } from 'react'
import Extra from './Extra'

function Dish({ dish, orders, setOrders, totalPrice, handleChange, handleChangeExtra, setTotalPrice }) {
    const [count, setCount] = useState(1)
    let copytotalPrice = new Decimal(totalPrice)
    const handleDishCount = (e, price) => {
        let newCounter = new Decimal(e.target.value)
        let formatedPrice = new Decimal(price)
        let copyCount = new Decimal(count)

        copytotalPrice = copytotalPrice.sub(formatedPrice.mul(copyCount)).add(formatedPrice.mul(newCounter))
        setTotalPrice(copytotalPrice)
        setCount(newCounter);
        let index = orders.findIndex((order) => (order.id == dish.id));
        let copyOrder = [...orders]
        if (index != -1) {
            copyOrder[index].quantity = newCounter.toString()
        }
        setOrders(copyOrder)
    }
    return (
        <div className={`d-flex flex-column col-md-6 col-lg-4 dish ${dish.dish} mb-2`}>
            <div className='m-auto m-md-0 d-flex align-items-start gap-3 w-100'>
                <input checked={orders?.filter((order) => (order.id == dish.id)).length > 0} id={`check_${dish.id}`} type='checkbox' className='mt-1' onChange={() => handleChange(dish)} />
                <label for={`check_${dish.id}`} className='checkbox_label w-100'>
                    <div className='w-100'>
                        <span className='title_dish'>{dish.number} {dish.dish}</span>
                        {
                            JSON.parse(dish.desc).map((desc) => (
                                <p className='m-0'>{desc}</p>
                            ))
                        }
                    </div>
                    <div className='price d-flex w-100 gap-2 align-items-center'>
                        <div><input id={`input_${dish.id}`} disabled={orders?.filter((order) => (order.id == dish.id)).length == 0} type='number' min={1} defaultValue={1} onChange={(e) => handleDishCount(e, dish.price)} /></div>
                        <div>{dish.price} {dish.currency}</div>
                    </div>
                </label>
            </div>
            {
                dish.extras.length > 0 &&
                JSON.parse(dish.extras).map((extra, index) => (
                    <Extra maxCount={count} handleChangeExtra={handleChangeExtra} setOrders={setOrders} totalPrice={totalPrice} setTotalPrice={setTotalPrice} extra={extra} dish={dish} index={index} orders={orders} />
                ))
            }
        </div>
    )
}

export default Dish