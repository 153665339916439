import React from 'react'

function Thanks() {
    return (
        <div className='thanks'>
            <div class="header py-3">
                <p class="header_sub_title">Asia restaurant</p>
                <h1 class="header_title">Family</h1>
            </div>
            <div class="p-5">
                <h2 class="title py-5">Thank you for your order</h2>
            </div>
        </div>
    )
}

export default Thanks