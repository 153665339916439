import React from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

function PrivateRoute() {
    return (
        localStorage.getItem("loged_in") ? <Outlet /> : <Navigate to={"/login"} />

    )
}

export default PrivateRoute