import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { FaSquareWhatsapp } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Admin() {
    const [orders, setOrders] = useState({
        normal: "",
        filtered: ""
    })
    const [shopStatus, setShopStatus] = useState()
    const [active, setActive] = useState("all")
    const [loading, setLoading] = useState(false)
    const [loadingOrders, setLoadingOrders] = useState(false)
    const getAllOrders = async () => {
        setLoadingOrders(false)
        await axios.get("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=getAllOrders").then((res) => {
            console.log(res)
            setShopStatus(res.data.open)
            setLoadingOrders(true)
            setOrders({ ...orders, normal: res.data.row, filtered: res.data.row })
        }).catch((err) => { })
    }
    useEffect(() => {
        getAllOrders()
    }, [])
    const completeOrder = async (id) => {
        let form = new FormData();
        form.append("id", id)
        await axios.post("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=completeOrder", form).then((res) => {
            console.log(res)
            if (res.data.result == "success") {
                setLoading(false)
                getAllOrders()
            }
        }).catch((err) => {

        })
    }
    const handleClick = async (e, id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure this order is complete ?",
            showCancelButton: true,
            confirmButtonText: "yes",
            cancelButtonText: "no",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setLoading(true)
                completeOrder(id)
            }
        });

    }
    const filter = (e, status) => {
        if (status != "All") {
            let filterd = orders.normal.filter((order) => (
                order.complete == status
            ))
            setOrders({ ...orders, filtered: filterd })
            setActive(status)
        } else {
            setOrders({ ...orders, filtered: orders.normal })
            setActive("all")
        }
    }
    const handleShopStatus = async (form) => {
        await axios.post("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=changeShopStatus", form).then((res) => {
            if (res.data.result == "success") {
                setLoading(false);
            }
        }).catch((err) => {

        })
    }
    const handleShop = (e) => {
        Swal.fire({
            icon: "warning",
            title: `Are you sure you want to open the store?`,
            showCancelButton: true,
            confirmButtonText: "yes",
            cancelButtonText: "no",
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let form = new FormData();
                form.append("forceOpen", e.target.value)
                setLoading(true)
                handleShopStatus(form)
            } else {
                e.target.value = shopStatus
            }
        });
    }
    return (
        <div className='orders_page mx-auto'>
            {loading &&
                <div className='loader_overlay'>
                    <div id="cooking">
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                        <div class="bubble"></div>
                        <div id="area">
                            <div id="sides">
                                <div id="pan"></div>
                                <div id="handle"></div>
                            </div>
                            <div id="pancake">
                                <div id="pastry"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                loadingOrders &&
                <div className='mb-2'>
                    <span className='me-2'>Shop ist :</span>
                    <select onChange={handleShop}>
                        <option selected={shopStatus == true} value={"true"}>Offen</option>
                        <option selected={shopStatus == false} value={"false"}>Geben Sie „Normal“ zurück</option>
                    </select>
                </div>
            }
            <div className='header_admin'>Willkommen Chefkoch, hier sind die Essensbestellungen</div>
            {loadingOrders ?
                <>
                    <div className='d-flex gap-3 py-3 flex-wrap'>
                        <div className={`filter ${active == "all" ? "active" : ""}`} onClick={(e) => filter(e, "All")}>Alle</div>
                        <div className={`filter ${active == true ? "active" : ""}`} onClick={(e) => filter(e, true)}>Vollendet</div>
                        <div className={`filter ${active == false ? "active" : ""}`} onClick={(e) => filter(e, false)}>unvollständig</div>
                    </div>
                    <div className='cards_container'>
                        {orders.filtered.length > 0 ?
                            orders.filtered?.map((order) => (
                                <div>
                                    <div className='card'>
                                        <div className='contact_icons d-flex align-items-center gap-2'>
                                            <a href={`https://wa.me/+49${order.phone}`}>
                                                <FaSquareWhatsapp className='whatsapp' />
                                            </a>
                                            <a href={`tel:+49${order.phone}`}>
                                                <IoCall />
                                            </a>
                                        </div>
                                        <div className='card_header'>{order.name}</div>
                                        <ul className='card_body'>
                                            {order.dishes.map((dish, index) => (
                                                <li>
                                                    <div>
                                                        <span>{dish.dish}</span>
                                                        <span className='text-end text-nowrap'><span className='me-1'>Q:( {dish.quantity} )</span>   {dish.price} {order.currency}</span>
                                                    </div>
                                                    <div>
                                                        {console.log(dish.extras)}
                                                        {dish.desc != null && JSON.parse(dish.desc).map((desc) => (
                                                            <p class="m-0">{desc}</p>
                                                        ))}

                                                    </div>

                                                    {dish.extras?.length > 0 &&
                                                        dish.extras.map((extra) => (
                                                            <div>
                                                                <p class="m-0">{extra.extra}</p>
                                                                <p class="m-0 text-end d-flex text-nowrap gap-2"><span>Q:( {extra.quantity} )</span>+ {extra.price} €</p>
                                                            </div>
                                                        ))
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                        <div className='d-flex justify-content-between'>
                                            <p>Gesamtpreis</p>
                                            <p>{order.totalPrice} {order.currency}</p>
                                        </div>
                                        <div className='card_footer'>
                                            <button disabled={order.complete} className={`btn btn_complete ${order.complete ? "completed" : ""}`} onClick={(e) => handleClick(e, order.id)}>{order.complete ? "Vollendet" : "unvollständig"}</button>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : <p className='empty_data'>Theres No Data</p>
                        }
                    </div>
                </>
                :
                <div className='h-300px'>
                    <span className='spanner'>
                        <svg viewBox="0 0 200 200">
                            <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                                <stop offset="0" stop-color="#FDE0B5"></stop>
                                <stop offset=".3" stop-color="#FDE0B5" stop-opacity=".9"></stop>
                                <stop offset=".6" stop-color="#FDE0B5" stop-opacity=".6"></stop>
                                <stop offset=".8" stop-color="#FDE0B5" stop-opacity=".3"></stop>
                                <stop offset="1" stop-color="#FDE0B5" stop-opacity="0"></stop>
                            </radialGradient>
                            <circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
                                <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite">
                                </animateTransform>
                            </circle>
                            <circle transform-origin="center" fill="none" opacity=".2" stroke="#941528" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70">
                            </circle>
                        </svg>
                    </span>
                </div>
            }
        </div>
    )
}

export default Admin