import React, { useState } from 'react'
import logo from '../assets/images/logo.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function Login() {
    const navigate = useNavigate()
    const [form, setForm] = useState({
        email: "",
        password: ""
    })
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const handleChangeName = (e) => {
        setForm({ ...form, email: e.target.value })
        setError("")
    }
    const handleChangePassword = (e) => {
        setForm({ ...form, password: e.target.value })
        setError("")
    }
    const handleSubmit = () => {
        if (form.password != "" && form.email != "") {
            setLoading(true)
            let formData = new FormData();
            formData.append("email", form.email)
            formData.append("password", form.password)
            axios.post("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=login", formData).then((res) => {
                if (res.data.result == "success") {
                    setLoading(false)
                    localStorage.setItem("loged_in", true)
                    navigate("/admin");
                } else {
                    setError("The password or username incorrect")
                }
            }).catch((err) => {

            })
        } else {
            setError("The password or username incorrect")
        }
    }
    return (
        <div className='login'>
            <div class="form">
                <div class="logo_container">
                    <img src={logo} className='logo' />
                </div>
                <div className='d-flex flex-column justify-content-between'>
                    <div>
                        <label for="email">Email</label>
                        <input id='email' type="email" value={form.uesrName} placeholder="Email" required onChange={handleChangeName} />
                        <label for="password">Password</label>
                        <input id='password' type="password" value={form.password} placeholder="Password" required onChange={handleChangePassword} />
                        <p className="error mt-2">{error}</p>
                    </div>
                    <button id="sign_btn" disabled={loading} onClick={handleSubmit}>
                        {loading ?
                            <span className='spanner'>
                                <svg viewBox="0 0 200 200">
                                    <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                                        <stop offset="0" stop-color="#FDE0B5"></stop>
                                        <stop offset=".3" stop-color="#FDE0B5" stop-opacity=".9"></stop>
                                        <stop offset=".6" stop-color="#FDE0B5" stop-opacity=".6"></stop>
                                        <stop offset=".8" stop-color="#FDE0B5" stop-opacity=".3"></stop>
                                        <stop offset="1" stop-color="#FDE0B5" stop-opacity="0"></stop>
                                    </radialGradient>
                                    <circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
                                        <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite">
                                        </animateTransform>
                                    </circle>
                                    <circle transform-origin="center" fill="none" opacity=".2" stroke="#941528" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70">
                                    </circle>
                                </svg>
                            </span>
                            : "Get Started"
                        }
                    </button>
                </div>

            </div>
        </div>
    )
}

export default Login

