import React, { useEffect, useRef, useState } from 'react'
import logo from '../assets/images/logo.png'
import banner from '../assets/images/banner.jpg'
import closedSign from '../assets/images/closeSign.webp'
import Checking from '../components/Checking';
import Thanks from '../components/Thanks';
import axios from 'axios';
import { FaLocationDot } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { Decimal } from 'decimal.js';
import Dish from '../components/Dish';

function Home() {
    const [stage, setStage] = useState(1)
    const [data, setData] = useState({
        normal: "",
        filtered: ""
    })
    const [totalPrice, setTotalPrice] = useState(0)
    const [currency, setCurrency] = useState()
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const [closed, setClosed] = useState(true)

    const getData = () => {
        setLoading(false)
        // get time berlin
        axios.get("http://worldtimeapi.org/api/timezone/Europe/Berlin").then((res) => {
            const currentTime = res.data.datetime;
            const date = new Date(currentTime);

            // Check if the time is outside the open hours (11:30 AM - 8:00 PM)
            //  2.30pm till 5.30pm
            const isBeforeOpen = date.getHours() < 11 || (date.getHours() === 11 && date.getMinutes() < 30);
            const isBreakTime = (date.getHours() > 14 || (date.getHours() === 14 && date.getMinutes() >= 30)) &&
                (date.getHours() < 18 || (date.getHours() === 17 && date.getMinutes() < 30));
            const isAfterClose = date.getHours() > 20;
            if (!isBeforeOpen && !isAfterClose && !isBreakTime) {
                setClosed(false)
            }
        })


        axios.get("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=getAllDishes").then((res) => {
            setLoading(true)
            if (res.data.open) {
                setClosed(false)
            }
            console.log(res)
            setCurrency(res.data.row[0].dishes[0].currency)
            setData({ ...data, normal: res.data.row, filtered: res.data.row })
        }).catch((err) => {

        })

    }
    useEffect(() => {
        getData()
    }, [])


    useEffect(() => {
        window.scroll(0, 0)
    }, [stage])

    const handleChange = (item) => {
        let price = new Decimal(totalPrice);
        let dishPrice = new Decimal(item.price)
        if (orders.length > 0) {
            let index = orders.findIndex((order) => (order.id == item.id));
            let copyOrder = [...orders]
            if (index != -1) {
                let dishQuantity = new Decimal(copyOrder[index].quantity)
                for (let x = 0; x < copyOrder[index].extras.length; x++) {
                    let extrasPirce = new Decimal(copyOrder[index].extras[x].price)
                    let extrasQuantity = new Decimal(copyOrder[index].extras[x].quantity)
                    price = price.sub(extrasPirce.mul(extrasQuantity))
                    document.getElementById(`check_${copyOrder[index].extras[x].id}_extra_${item.id}`).checked = false
                    document.getElementById(`input_extra_${copyOrder[index].extras[x].id}_${item.id}`).value = 1;
                }
                price = price.sub(dishPrice.mul(dishQuantity));
                setTotalPrice(price);
                copyOrder.splice(index, 1)
                document.getElementById(`input_${item.id}`).value = 1;
            } else {
                copyOrder.push({
                    id: item.id,
                    number: item.number,
                    dish: item.dish,
                    category: item.category,
                    desc: item.desc,
                    price: item.price,
                    currency: item.currency,
                    quantity: document.getElementById(`input_${item.id}`).value,
                    extras: []
                })
                setOrders(copyOrder)
                setTotalPrice(price.add(dishPrice));
            }
            setOrders(copyOrder)
        } else {
            let copyOrder = [...orders]
            copyOrder.push({
                id: item.id,
                number: item.number,
                dish: item.dish,
                category: item.category,
                desc: item.desc,
                price: item.price,
                currency: item.currency,
                quantity: document.getElementById(`input_${item.id}`).value,
                extras: []
            })
            setOrders(copyOrder)
            setTotalPrice(price.add(dishPrice));
        }
    }
    const handleChangeExtra = (e, extra_id, dish_id, extra, extra_quantity) => {
        let price = new Decimal(totalPrice);
        let extraPrice = new Decimal(extra.price)
        let extraCount = new Decimal(document.getElementById(`input_extra_${extra_id}_${dish_id}`)?.value || extra_quantity)
        let index = orders.findIndex((order) => (order.id == dish_id));
        if (index != -1) {
            let copyOrder = [...orders]
            if (e.target.checked) {
                copyOrder[index].extras.push({
                    id: extra_id,
                    extra: extra.extra,
                    price: extra.price,
                    quantity: document.getElementById(`input_extra_${extra_id}_${dish_id}`).value,
                })
                setTotalPrice(price.add(extraPrice))
            }
            else {
                let extra_index = copyOrder[index].extras.findIndex((ext) => (ext.id == extra_id));
                if (extra_index != -1) {
                    copyOrder[index].extras.splice(extra_index, 1)
                    setTotalPrice(price.sub(extraPrice.mul(extraCount)))
                }
                if (document.getElementById(`input_extra_${extra_id}_${dish_id}`))
                    document.getElementById(`input_extra_${extra_id}_${dish_id}`).value = 1;
            }
            setOrders(copyOrder)
        }
        console.log(orders)
    }
    function filter(value) {
        if (value != "") {
            const filteredData = data.normal.map((category) => {
                const filteredDishes = category.dishes.filter((dish) =>
                    dish.dish.toLowerCase().includes(value) || dish.number.toString().includes(value)
                );
                return filteredDishes.length > 0
                    ? { category: category.category, dishes: filteredDishes }
                    : null; // Exclude categories with no matches
            }).filter((item) => item !== null);
            setData({ ...data, filtered: filteredData })
        } else {
            setData({ ...data, filtered: data.normal })
        }

    }

    return (
        // closed ?
        //     <div className='closedSign_overlay'>
        //         <img src={logo} className='logo' />
        //         <div className='closedSign_container'>
        //             <img src={closedSign} className='w-100' />
        //         </div>
        //     </div>
        //     :
        //     <>
        stage == 1 ?
            <>
                <div class="nav">
                    <div>
                        Wir haben von 11.30 bis 14.30 Uhr geöffnet und wieder von 17.30 bis 20.00 Uhr und Von Dienstag bis Sonntag geöffnet, aber auch an Feiertagen, die auf einen Montag fallen haben wir geöffnet
                    </div>
                </div>
                <div class="header">
                    <div class="row w-100 m-auto">
                        <div class="px-5 pb-4 col-md-6 order-2 order-md-1">
                            <img src={logo} class="logo" />
                            <p class="header_sub_title">Asia restaurant</p>
                            <h1 class="header_title">Family</h1>
                            <p class="header_p">Spezialitäten der asiatischen Küche</p>
                        </div>
                        <div class="right_side col-md-6 order-1 order-md-2">
                            {/* <img src={banner} class="banner w-100" /> */}
                        </div>
                    </div>
                </div>
                <div class="p-5">
                    <h2 class="title">Wählen Sie Ihre Gerichte</h2>
                    <div class="search_container mb-3">
                        <div class="icon_container">
                            <span class="">
                                <svg class="" width="80" height="80" viewBox="0 0 80 80" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M58.64 31.2467C58.5283 28.3467 57.4933 25.725 56.09 24.3233C55.9353 24.1685 55.8125 23.9847 55.7288 23.7824C55.6451 23.5801 55.6021 23.3633 55.6022 23.1444C55.6022 22.9255 55.6454 22.7087 55.7293 22.5065C55.8131 22.3043 55.936 22.1206 56.0908 21.9658C56.2457 21.8111 56.4295 21.6884 56.6318 21.6047C56.834 21.521 57.0508 21.4779 57.2697 21.478C57.4887 21.4781 57.7054 21.5213 57.9076 21.6051C58.1099 21.689 58.2936 21.8118 58.4483 21.9667C60.61 24.1283 61.8367 27.6483 61.9717 31.1167C62.1067 34.6083 61.1517 38.42 58.4817 41.09C58.1689 41.4025 57.7449 41.578 57.3027 41.5778C56.8606 41.5777 56.4367 41.4019 56.1242 41.0892C55.8116 40.7764 55.6362 40.3524 55.6363 39.9102C55.6365 39.4681 55.8123 39.0442 56.125 38.7317C57.9433 36.915 58.7533 34.125 58.6417 31.2467"
                                        fill="black" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M31.7733 45.64C28.4689 41.7346 26.6591 36.7825 26.6667 31.6667C26.6667 19.7 36.3667 10 48.3333 10C60.3 10 70 19.7 70 31.6667C70 43.6333 60.3 53.3333 48.3333 53.3333C43.0083 53.3333 38.1333 51.4133 34.36 48.2267L35 51.9067L17.86 69.0467C16.5883 70.3183 14.5267 70.3183 13.2567 69.0467L10.9533 66.7433C9.68167 65.4717 9.68167 63.41 10.9533 62.14L28.0933 45L31.7733 45.64ZM48.3333 50C38.2083 50 30 41.7917 30 31.6667C30 21.5417 38.2083 13.3333 48.3333 13.3333C58.4583 13.3333 66.6667 21.5417 66.6667 31.6667C66.6667 41.7917 58.4583 50 48.3333 50ZM20.1117 62.0817L31.42 50.7733L31.095 48.9067L29.2267 48.58L17.885 59.9217L20.1117 62.0817ZM15.5283 62.2783L13.365 64.4417L15.5583 66.635L17.755 64.4383L15.5283 62.2783Z"
                                        fill="black" />
                                </svg>
                            </span>
                        </div>

                        <input type='text' className='search_input' onChange={(e) => filter(e.target.value)} />
                    </div>
                    {loading ?
                        data.filtered.length > 0 ?
                            data.filtered?.map((item, index) => (
                                <>
                                    <h2 className='category'>{item.category}</h2>
                                    <div className='row gy-2'>
                                        {
                                            item.dishes?.map((dish, dish_index) => (
                                                // <div className={`d-flex flex-column col-md-6 col-lg-4 dish ${dish.dish}`}>
                                                //     <div className='m-auto m-md-0 d-flex align-items-start gap-3 w-100'>
                                                //         <input checked={orders?.filter((order) => (order.id == dish.id)).length > 0} id={`check_${dish.id}`} type='checkbox' className='mt-1' onChange={() => handleChange(dish)} />
                                                //         <label for={`check_${dish.id}`} className='checkbox_label w-100'>
                                                //             <div>
                                                //                 <span className='title_dish'>{dish.number} {dish.dish}</span>
                                                //                 {
                                                //                     JSON.parse(dish.desc).map((desc) => (
                                                //                         <p className='m-0'>{desc}</p>
                                                //                     ))
                                                //                 }
                                                //             </div>
                                                //             <div className='price'>
                                                //                 <span>{dish.price} {dish.currency}</span>
                                                //                 <span><input type='number' min={1} onChange={handleDishCount} /></span>
                                                //             </div>
                                                //         </label>
                                                //     </div>
                                                //     {
                                                //         dish.extras.length > 0 &&
                                                //         JSON.parse(dish.extras).map((extra, index) => (
                                                //             <div className='d-flex align-items-center gap-3 w-100 ps-5 mt-2'>
                                                //                 <input disabled={orders?.filter((order) => (order.id == dish.id)).length == 0} checked={orders?.filter((order) => (order.id == dish.id &&
                                                //                     order.extras?.filter((extra) => (extra.id == index)).length > 0)).length > 0} id={`check_${index}_extra_${dish.id}`} type='checkbox' onChange={(e) => handleChangeExtra(e, index, dish.id, extra)} />
                                                //                 <label for={`check_${index}_extra_${dish.id}`} className='checkbox_label w-100'>
                                                //                     <span>{extra.extra}</span><span className='price'>+{extra.price} €</span>
                                                //                 </label>
                                                //             </div>
                                                //         ))
                                                //     }
                                                // </div>
                                                <Dish setTotalPrice={setTotalPrice} dish={dish} setOrders={setOrders} orders={orders} totalPrice={totalPrice} handleChange={handleChange} handleChangeExtra={handleChangeExtra} />
                                            ))
                                        }
                                    </div>
                                </>
                            ))
                            : <p className='empty_data'>Theres No Data</p>
                        :
                        <div className='h-300px'>
                            <span className='spanner'>
                                <svg viewBox="0 0 200 200">
                                    <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                                        <stop offset="0" stop-color="#FDE0B5"></stop>
                                        <stop offset=".3" stop-color="#FDE0B5" stop-opacity=".9"></stop>
                                        <stop offset=".6" stop-color="#FDE0B5" stop-opacity=".6"></stop>
                                        <stop offset=".8" stop-color="#FDE0B5" stop-opacity=".3"></stop>
                                        <stop offset="1" stop-color="#FDE0B5" stop-opacity="0"></stop>
                                    </radialGradient>
                                    <circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
                                        <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite">
                                        </animateTransform>
                                    </circle>
                                    <circle transform-origin="center" fill="none" opacity=".2" stroke="#941528" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70">
                                    </circle>
                                </svg>
                            </span>
                        </div>
                    }
                    <div class="row w-100 mt-5">
                        <div class="col-md-6">
                            <span class="total_pirce m-auto d-flex justify-content-between"><span>Gesamtpreis</span><span>{totalPrice.toString()} {currency}</span></span>
                        </div>
                        <div class="col-md-6">
                            <button disabled={orders.length == 0 || closed} class={`button m-auto mt-3 mt-md-0 ${closed ? "closed_btn" : ""}`} onClick={() => setStage(2)}>{closed ? "Closed" : "Order Now"}</button>
                        </div>
                    </div>
                </div >
                <div class="footer px-5  pb-4">
                    <div class="row w-100 border-bottom border-dark py-4">
                        <div class="col-md-6">
                            <p class="header_sub_title">Asia restaurant</p>
                            <h1 class="header_title">Family</h1>
                        </div>
                        <div class="col-md-6">
                            <img src={logo} class="logo_footer" />
                        </div>
                    </div>
                    <div class="py-2 w-100">
                        <div class="flex-wrap d-flex justify-content-between gap-2 w-100">
                            <div>
                                <div class="d-flex gap-3 align-items-center mb-1">
                                    <FaLocationDot className='fs-4' />
                                    <span class="text">Käthe-Kollwitz-Str. 27 19288 Ludwigslust</span>
                                </div>
                                <div class="d-flex gap-3 align-items-center mb-1">
                                    <FaEnvelope className='fs-4' />
                                    <span class="text">asiafamilyrestaurantlwl@gmail.com</span>
                                </div>
                                <div class="d-flex gap-3 align-items-center mb-1">
                                    <FaPhone className='fs-4' />
                                    <span class="text">03874-663968</span>
                                </div>
                            </div>
                            <div class="d-flex m-sm-0 m-auto  justify-content-end gap-2 align-items-end  w-25">
                                <div className='p-1 rounded bg-black w-fit h-fit'>
                                    <FaInstagram className='fs-3 text-white' />
                                </div>
                                <div className='p-1 rounded bg-black w-fit h-fit'>
                                    <FaXTwitter className='fs-3 text-white' />
                                </div>
                                <div className='p-1 rounded bg-black w-fit h-fit'>
                                    <FaFacebookF className='fs-3 text-white' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            : stage == 2 ?
                <Checking setData={setData} data={data} setStage={setStage} setTotalPrice={setTotalPrice} setOrders={setOrders} orders={orders} totalPrice={totalPrice} currency={currency} handleChangeExtra={handleChangeExtra} />
                : <Thanks />
        // </>

    )
}

export default Home
