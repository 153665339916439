import axios from 'axios';
import { Decimal } from 'decimal.js';
import React, { useState } from 'react'
import Swal from 'sweetalert2';

function Checking({ setStage, setData, data, setOrders, orders, totalPrice, currency, setTotalPrice, handleChangeExtra }) {
    const [formData, setFormData] = useState({
        name: "",
        phone: ""
    })
    const [loading, setLoading] = useState(false)
    const handleBack = () => {
        setTotalPrice(0)
        setOrders([]);
        setData({ ...data, filtered: data.normal })
        setStage(1)
    }
    const handleClick = (e, item, container) => {
        let price = new Decimal(totalPrice);
        let dishPrice = new Decimal(item.price)
        let index = orders.findIndex((order) => (order.id == item.id));
        if (index != -1) {
            let copyOrder = [...orders]
            let dishQuantity = new Decimal(copyOrder[index].quantity)
            for (let x = 0; x < copyOrder[index].extras.length; x++) {
                let extrasPirce = new Decimal(copyOrder[index].extras[x].price)
                let extrasQuantity = new Decimal(copyOrder[index].extras[x].quantity)
                price = price.sub(extrasPirce.mul(extrasQuantity))
            }
            price = price.sub(dishPrice.mul(dishQuantity));
            setTotalPrice(price);
            copyOrder.splice(index, 1)
            setOrders(copyOrder)
            if (document.getElementById(e.target.id + "_container"))
                document.getElementById(e.target.id + "_container").remove()
        }

    }

    const handleConfirm = async () => {
        if (formData.name != "" && formData.phone != "" && orders.length > 0) {
            setLoading(true)
            let result = {
                name: formData.name,
                phone: formData.phone,
                dishes: [],
                categories: [],
                desc: [],
                prices: [],
                extras: [],
                quantity: [],
                currency: currency,
                total_price: totalPrice
            }
            for (let i = 0; i < orders.length; i++) {
                result.dishes.push(orders[i].dish)
                result.categories.push(orders[i].category)
                result.desc.push(orders[i].desc)
                result.prices.push(orders[i].price.toString())
                result.extras.push(orders[i].extras)
                result.quantity.push(orders[i].quantity)
            }
            let form = new FormData();
            form.append("name", result.name)
            form.append("phone", result.phone)
            form.append("dishes", JSON.stringify(result.dishes))
            form.append("categories", JSON.stringify(result.categories))
            form.append("desc", JSON.stringify(result.desc))
            form.append("prices", JSON.stringify(result.prices))
            form.append("extras", JSON.stringify(result.extras))
            form.append("quantity", JSON.stringify(result.quantity))
            form.append("currency", result.currency)
            form.append("total_price", result.total_price)
            await axios.post("https://script.google.com/macros/s/AKfycbwrWLzX3irpRK-nNadJAnaEeIc4jzzjm9DxIw-UIX_wF2kJaq2qVxCz6aCzh5dJG7V1cQ/exec?api=setOrder", form).then((res) => {
                if (res.data.result == "success") {
                    setLoading(false);
                    setStage(3)
                }
                console.log(res)
            }).catch((err) => {
            })
        } else {
            Swal.fire({
                icon: "error",
                title: orders.length > 0 ? "Please Enter the name and the phone number" : "Please Choose some food before confirm",
                confirmButtonText: "ok",
            })
        }
    }
    return (
        <div className='checking'>
            {loading &&
                <div className='loader_overlay'>
                    <div class="loader m-auto"></div>
                </div>
            }
            <div class="header py-3">
                <p class="header_sub_title">Asia restaurant</p>
                <h1 class="header_title">Family</h1>
            </div>
            <div class="p-5">
                <h2 class="title">Deine Bestellungen...</h2>
                {
                    orders?.map((item, item_index) => (
                        <div id={`check_${item_index}_container`} className='mt-3 d-flex flex-column w-fit'>
                            <div className='m-auto m-md-0 d-flex align-items-start gap-3'>
                                <input checked="true" id={`check_${item.id}`} type='checkbox' className='mt-1' onChange={(e) => handleClick(e, item)} />
                                <label for={`check_${item.id}`} className='checkbox_label d-flex gap-5'>
                                    <div>
                                        <span>{item.number} {item.dish}</span>
                                        {
                                            JSON.parse(item.desc).map((desc) => (
                                                <p>{desc}</p>
                                            ))
                                        }
                                    </div>
                                    <div className=' d-flex w-100 gap-2'>
                                        <div><span>(Q:</span> <span>{item.quantity})</span></div>
                                        <span>{item.price} {item.currency}</span>
                                    </div>
                                </label>
                            </div>
                            {
                                item.extras.length > 0 &&
                                item.extras.map((extra, index) => (
                                    <div className='d-flex align-items-center gap-3 w-100 ps-5 mt-2'>
                                        <input checked={true} id={`check_${item.id}_extra`} type='checkbox' onChange={(e) => handleChangeExtra(e, index, item.id, extra, extra.quantity)} />
                                        <label for={`check_${item.id}_extra`} className='checkbox_label w-100'>
                                            <span>{extra.extra}</span><span className='price ms-auto'><span className='me-1'>Q:({extra.quantity})</span>+{extra.price} €</span>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
                <div class="row w-100 my-5">
                    <div class="col-md-6">
                        <div class="total_pirce m-auto">
                            <span>Gesamtpreis</span>
                            <span>{totalPrice.toString()} {currency}</span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <a class="button m-auto mt-3 mt-md-0" onClick={handleBack}>Zurück zum Menü</a>
                    </div>
                </div>
                <div>
                    <h2 class="title">Ein Schritt zum Abschluss Ihrer Bestellung </h2>
                    <div class="mt-5 ps-lg-5   confirm_form">
                        <div class="d-flex">
                            <label for="name">Name</label>
                            <input id="name" value={formData.name} class="input" type="text" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                        </div>
                        <div class="d-flex mt-3">
                            <label for="phone_number">Telefonnummer</label>
                            <input id="phone_number" value={formData.phone} class="input" type="text" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                        </div>
                        <div class="mt-4">
                            <button class="button m-auto" onClick={handleConfirm}>Bestätigen</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Checking